import { h } from 'preact';
import { Router } from 'preact-router';
import AsyncRoute from 'preact-async-route';

// Code-splitting is automated for `routes` directory
import Account from '../routes/account';
import api from "../tool/fetch";
import { useEffect, useState } from 'preact/hooks';


const App = () => {
	const [serviceURL, setServiceURL] = useState('')

	useEffect(() => {
		api({
			url: '/api/v1/common/customer/service',
			method: 'GET',
		}).then(res => {
			setServiceURL(res.data.url)
		})
	})
	return (
		<div id="app">
			<main>
				<Router>
					<Account path="/account" />
					<AsyncRoute
						path="/crypto"
						getComponent={() => import('../routes/crypto').then(module => module.default)}
						loading={() => <div>loading...</div>}
					/>
				</Router>
				<a href={serviceURL} class="service"> </a>
			</main>
		</div>
	)
};

export default App;
