function getQueryParam(param) {
  let value = 0;
  try {
    let urlParams = new URLSearchParams(window.location.search);
    value = urlParams.get(param);
  } catch (error) {
    value = 0;
  }
  return value;
}
const urls = [
  "https://1.letsvpn1.top",
  "https://3.letsvpn1.top",
  "https://4.letsvpn1.top",
  "https://2.letsgo1.top",
];
const base = urls[getQueryParam("a")||0];
const api = ({ url, method, params }) => {
  const Bearer = window.atob(localStorage.getItem("Bearer"));
  let formData = "";
  if (params) {
    Object.keys(params).forEach((key) => {
      formData += `${key}=${params[key]}&`;
    });
  }

  return new Promise((resolve, reject) => {
    window
      .fetch(base + url, {
        method,
        headers: {
          Authorization: Bearer,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        timeout: 5000,
        body: formData || null,
      })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch(reject);
  });
};

export default api;
